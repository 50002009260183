"use client"
import { classNames } from "@local/utils/src/classNames"
import styles from "./styles.module.css"
import { Checkmark } from "../Checkmark"
import { GET_ANY_CHANNEL_ID } from "../../const"
import type { Payload } from "@local/payload-client/src/types"
import MuxPlayer from "@mux/mux-player-react"
import HydrationContainer from "../HydrationContainer"

interface Props {
  block: Payload.SectionVideoBlock
  page: Payload.Anchor
  channel?: Payload.AnchorChannelId
}

export const SectionWithVideo = ({ block, page, channel }: Props) => {
  const { IS_BAERSKIN } = GET_ANY_CHANNEL_ID(channel)

  return (
    <section
      id={block.anchor as string}
      className={classNames(
        styles.Section,
        block.backgroundColor !== "#FFFFFF" ? "py-4" : ""
      )}
      style={{
        backgroundColor: block.backgroundColor,
        color: block.textColor,
      }}
    >
      <div className={styles.Container}>
        <div
          className={classNames(
            block.alternativePost ? "md:order-last" : "md:order-first",
            "order-last"
          )}
          data-testid="image"
        >
          <h3 className={styles.Title_Mobile}>{block.title}</h3>
          {block.videoId ? (
            <HydrationContainer>
              <MuxPlayer
                autoPlay="muted"
                defaultHiddenCaptions
                loop
                metadataVideoTitle={block.title}
                muted
                nohotkeys
                playbackId={block.videoId}
                streamType="on-demand"
                maxResolution="720p"
                className="w-full aspect-square"
              />
              <style>
                {`
                  mux-player {
                    --controls: none;
                    --media-object-fit: cover;
                    inset: 0;
                    z-index: 5;
                  }
                  mux-player::part(gesture-layer) {
                    display: none;
                  }
                `}
              </style>
            </HydrationContainer>
          ) : null}
        </div>

        <div
          className={classNames(
            block.alternativePost ? "md:order-first" : "md:order-last",

            styles.Section__Content,
            "structured-text order-last"
          )}
        >
          <h3 className={styles.Title_Desktop}>{block.title}</h3>
          {block.text && (
            <div dangerouslySetInnerHTML={{ __html: block.text }} />
          )}
          {block.list && block.list?.length > 0 && (
            <ul style={{ listStyle: "none" }}>
              {block.list.map((item, i) => (
                <li
                  key={`list-${item.id}-${i}`}
                  className="grid items-center grid-cols-4 gap-4"
                >
                  <div className="col-span-1">
                    {/* eslint-disable-next-line */}
                    <img src={item.image?.url} alt={item.image?.alt ?? ""} />
                  </div>
                  <div className="col-span-3">
                    <span
                      className="mr-2"
                      style={
                        page.buttonBgColor
                          ? {
                              color: IS_BAERSKIN
                                ? "#4B7F0D"
                                : page.buttonBgColor,
                            }
                          : {}
                      }
                    >
                      <Checkmark />
                    </span>{" "}
                    {item.text && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.text as string,
                        }}
                        className="col-span-9"
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  )
}

export default SectionWithVideo
