import { isBrowser } from "@local/utils/src/isBrowser"
import type { CSSProperties, ReactNode } from "react"
import { Scrollchor } from "react-scrollchor"

interface Props {
  children: ReactNode
  className?: string
  to: string
  onClick?: (
    focusableElement?:
      | HTMLElement
      | React.MutableRefObject<HTMLElement | null>
      | undefined
  ) => void
  style?: CSSProperties
  offset?: number
}

export const AnchorScroll = ({
  children,
  className,
  style,
  to,
  onClick,
  offset,
}: Props) => {
  return (
    <Scrollchor
      animate={{
        offset: offset ?? -400,
        duration: 400,
      }}
      beforeAnimate={onClick ? () => onClick() : undefined}
      className={className}
      style={style}
      to={to}
    >
      {children}
    </Scrollchor>
  )
}

export default AnchorScroll
